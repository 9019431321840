// Define an array of RSS URLs with custom titles
var rssUrls = [
    { url: 'rss/retro.xml', title: 'RetroSpace' },
    //{ url: 'rss/cphive.xml', title: 'Chicken Police: Into the HIVE!' },
    // Add more URLs with titles as needed
  ];
  
  // Function to fetch and parse RSS feed
  function fetchAndParseRSS(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          var parser = new DOMParser();
          var xmlDoc = parser.parseFromString(xhr.responseText, 'text/xml');
          callback(null, xmlDoc);
        } else {
          callback(new Error('Failed to fetch RSS'));
        }
      }
    };
    xhr.open('GET', url, true);
    xhr.send();
  }
  
  // Function to extract relevant information from XML
  function extractInfoFromXML(xmlDoc) {
    var items = xmlDoc.querySelectorAll('item');
    var newsItems = [];
  
    items.forEach(function(item) {
      var title = item.querySelector('title').textContent;
      var description = item.querySelector('description').textContent;
      var link = item.querySelector('link').textContent;
      var thumbnail = item.querySelector('enclosure').getAttribute('url');
  
      var pubDate = new Date(item.querySelector('pubDate').textContent);
      var formattedDate = pubDate.toLocaleDateString();
  
      newsItems.push({
        title: title,
        description: description,
        link: link,
        thumbnail: thumbnail,
        pubDate: formattedDate
      });
    });
  
    return newsItems;
  }
  
  // Function to create HTML boxes
  function createHTMLBox(newsItem) {
    var box = document.createElement('div');
    box.classList.add('news-box');
    box.innerHTML = `
      
      
    <a href="${newsItem.link}" target="_blank">
        <img src="${newsItem.thumbnail}" alt="${newsItem.title}" title="${newsItem.title}" loading="lazy">
        <span class="box-content">
            <h2>${newsItem.title}</h2>
        </span>
    </a>
      
    `;
    return box;
  }
  /*
  <h3>${newsItem.customTitle}</h3>  
  <p>${newsItem.description}</p>
  <p>Date: ${newsItem.pubDate}</p>
  */

  // Function to sort news items by date
  function sortNewsByDate(newsItems) {
    return newsItems.sort(function(a, b) {
      return new Date(b.pubDate) - new Date(a.pubDate);
    });
  }
  
  // Function to display news items
  function displayNews(newsItems) {
    var newsContainer = document.getElementById('news-container');
  
    newsItems.forEach(function(item) {
      var box = createHTMLBox(item);
      newsContainer.appendChild(box);
    });
  }
  
  // Fetch and process each RSS feed
  function processRSSFeeds() {
    var allNewsItems = [];
    var counter = 0;
  
    function processNext() {
      if (counter < rssUrls.length) {
        fetchAndParseRSS(rssUrls[counter].url, function(error, xmlDoc) {
          if (!error) {
            var newsItems = extractInfoFromXML(xmlDoc);
            newsItems.forEach(function(item) {
              item.customTitle = rssUrls[counter].title; // Added custom title
            });
            allNewsItems.push.apply(allNewsItems, newsItems);
            counter++;
            processNext();
          } else {
            console.error(error);
          }
        });
      } else {
        var sortedNewsItems = sortNewsByDate(allNewsItems);
        displayNews(sortedNewsItems);
      }
    }
  
    processNext();
  }
  



  function showAllNews(button, id) {
      var container = document.getElementById(id)

      if(container)
        container.classList.add("opened");

      button.style.display = "none";
  }